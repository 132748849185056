const dev = {
    apiUrl: "localhost:4300"
}

const prod = {
    apiUrl: "https://api.zadhalal.com"
}

const local = {
    apiUrl: "localhost:4300"
}

export const CURRENT_ENV = "prod";

export const myConfig = () => {
    if (CURRENT_ENV === "prod") return prod;
    if (CURRENT_ENV === "dev") return dev;
    if (CURRENT_ENV === "local") return local;
    return prod;

}

